import { TYPE } from "vue-toastification";
import { apiCall, apiCallParams, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";
import i18n from "@/core/i18n/i18n";

const { t } = i18n.global;

export async function getOrganizationList(): Promise<any> {
  const resource = "/organizations";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getOrganization(organizationId: string): Promise<any> {
  const endPoint = `/organizations/${organizationId}`;
  const response = await apiCall("GET", endPoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function deleteOrganization(organizationId: string): Promise<any> {
  const endPoint = `/organizations/${organizationId}`;
  const response = await apiCall("DELETE", endPoint);
  if (response.code === 403 && response.message === t("Organization.LOGGED_IN_ORG_ERROR")) {
    showAlert(TYPE.ERROR, t("Organization.LOGGED_IN_ORG_ERROR"));
  } else if (response.code === 403 && response.message === t("Organization.SECURITY_VIOLATION")) {
    showAlert(TYPE.ERROR, t("Organization.SECURITY_VIOLATION"));
  } else if (response.code === 403 && response.message === t("Organization.LAST_ORG_ERROR")) {
    showAlert(TYPE.ERROR, t("Organization.LAST_ORG_ERROR"));
  } else if (response.code === 403 && response.message === t("Organization.ACTIVE_FLOWS_ERROR")) {
    showAlert(TYPE.ERROR, t("Organization.ACTIVE_FLOWS_ERROR"));
  } else if (response.code === 403 && response.message === t("Organization.AUTHENTICATION_ERROR")) {
    showAlert(TYPE.ERROR, t("Organization.AUTHENTICATION_ERROR"));
  } else {
    logResponse(response);
  }
  return response;
}

export async function addOrganization(organization: any): Promise<any> {
  const endPoint = "/organizations/create";
  const response = await apiCall("POST", endPoint, organization);
  logResponse(response);
  return response;
}

export async function editOrganization(organization: any): Promise<any> {
  const endPoint = "/organizations/update";
  const response = await apiCall("PUT", endPoint, organization);
  logResponse(response);
  return response;
}

export async function checkConnections(organization: any): Promise<any> {
  const endPoint = "/organizations/check";
  const response = await apiCall("PUT", endPoint, organization);
  if (response.code === 200) {
    return response.message;
  }
  logResponse(response);
  return undefined;
}

export async function getOrganizationListing(params: any): Promise<any> {
  const endpoint = "/organizations/list";
  const response = await apiCallParams("GET", endpoint, params);
  if (response.code === 200) {
    return response.page;
  }
  logResponse(response);
  return undefined;
}
