import { TYPE } from "vue-toastification";
import { size } from "lodash-es";
import { apiCall, apiCallParams, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";

export async function getRoleList(search: string): Promise<any> {
  const endpoint = "/roles/listing";
  const response = await apiCallParams("GET", endpoint, { search });
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getRole(roleId: string): Promise<any> {
  const resource = `/roles/${roleId}`;
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function deleteRole(roleId: string): Promise<any> {
  const resource = `/roles/delete/${roleId}`;
  const response = await apiCall("DELETE", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function checkOrganizationRole(roleId: string): Promise<any> {
  const resource = `/organization-role/role/${roleId}`;
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return size(response.result);
  }
  logResponse(response);
  return undefined;
}

export async function addRole(role: any): Promise<any> {
  const resource = "/roles";
  const response = await apiCall("POST", resource, role);
  if (response.code === 200) {
    return response.result;
  }

  if (response.code === 406) {
    showAlert(TYPE.WARNING, "Role with the same name exists.");
    return undefined;
  }
  logResponse(response);
  return undefined;
}

export async function editRole(role: any): Promise<any> {
  const resource = "/roles";
  const response = await apiCall("PUT", resource, role);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}
